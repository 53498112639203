<template>
  <div>
    <!--begin::Card-->
    <div class="card-body detailpage-touchless pa-0">
      <!--begin::Details-->
      <v-layout>
        <div class="border-right sidebar--detail px-2">
          <div class="py-3">
            <!--begin: Pic-->
            <div class="flex-shrink-0 text-center py-5">
              <div class="py-5">
                <v-img
                  class="profile-image mx-auto"
                  width="150"
                  :lazy-src="appCompanyLogo"
                  :src="appCompanyLogo"
                >
                </v-img>
              </div>
              <!--begin::Title-->
              <div class="d-flex justify-content-center flex-wrap mt-3">
                <div class="d-flex">
                  <span class="text-dark-75 font-size-h3 font-weight-bold">{{
                    appCompanyName
                  }}</span>
                </div>
              </div>
              <!--end::Title-->
            </div>
            <!--end::Pic-->

            <!--begin::Info-->
            <div class="flex-grow-1">
              <!--begin::Content-->
              <div class="mt-1">
                <table width="100%">
                  <!-- <tr>
                    <td class="py-1 px-0 font-weight-500" width="200">
                      Company Name
                    </td>
                    <td class="py-1">
                      <div class="">
                        <span
                          :content="`<span class=&quot;font-size-13&quot;>Company Name</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          ><v-icon class="mr-2" small>mdi-home-city</v-icon
                          >bthrust pvt ltd</span
                        >
                      </div>
                    </td>
                  </tr> -->
                  <tr>
                    <td width="200" class="py-1 px-0 font-weight-500">
                      Contact Name
                    </td>
                    <td class="py-1">
                      <div class="">
                        <span
                          :content="`<span class=&quot;font-size-13&quot;>User Name</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          ><i class="flaticon2-user mr-2 font-size-lg"></i
                          >{{ currentUser.user_name }}</span
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 px-0 font-weight-500">Email</td>
                    <td class="py-1">
                      <div class="">
                        <a
                          :content="`<span class=&quot;font-size-13&quot;>User Email</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          :href="'mailto:' + currentUser.user_email"
                          class="text-dark-50 text-hover-primary font-weight-500 mr-lg-8 mr-5 mb-lg-0 mb-2"
                          ><i class="flaticon2-new-email mr-2 font-size-lg"></i
                          >{{ currentUser.user_email }}</a
                        >
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="py-1 px-0 font-weight-500">Phone No.</td>
                    <td class="py-1">
                      <div class="">
                        <a
                          :content="`<span class=&quot;font-size-13&quot;>Phone Number</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          :href="'tel:' + currentUser.phone_number"
                          class="text-dark-50 text-hover-primary font-weight-500 mr-lg-8 mr-5 mb-lg-0 mb-2"
                          ><i class="flaticon2-phone mr-2 font-size-lg"></i
                          >{{ currentUser.phone_number }}</a
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 px-0 font-weight-500">Language</td>
                    <td class="py-1">
                      <div class="">
                        <span
                          :content="`<span class=&quot;font-size-13&quot;>Language</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >English</span
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 px-0 font-weight-500">Active From</td>
                    <td class="py-1">
                      <div class="">12 July 2022</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 px-0 font-weight-500">Country</td>
                    <td class="py-1">
                      <div class="">
                        <span
                          :content="`<span class=&quot;font-size-13&quot;>Country</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >Singapore</span
                        >
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td valign="top" class="py-1 px-0 font-weight-500">
                      Address
                    </td>
                    <td class="py-1">
                      <div class="">
                        <span
                          :content="`<span class=&quot;font-size-13&quot;>Address</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          ><v-icon small class="mr-2" color="grey"
                            >mdi-map</v-icon
                          >ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road
                        </span>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <!--end::Content-->
            </div>
            <!--end::Info-->
          </div>
        </div>
        <!--end::Details-->
        <v-col class="main-section--detail">
          <!--begin::Profile-->
          <v-tabs
            v-model="userTab"
            background-color="transparent"
            color="cyan"
            class="custom-tab-transparent sticky-tab-0"
          >
            <template v-for="(tab, index) in tabs">
              <v-tab
                class="font-size-16 font-weight-600 px-8"
                :key="index"
                :href="'#tab-' + tab.key"
                :disabled="tab.disabled"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                {{ tab.title }}
              </v-tab>
            </template>
          </v-tabs>
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 89vh position: relative"
          >
            <v-tabs-items v-model="userTab" touchless>
              <v-tab-item :value="'tab-overview'">
                <OverviewTab></OverviewTab>
              </v-tab-item>
              <v-tab-item :value="'tab-billing'">
                <BillingPlanTab></BillingPlanTab>
              </v-tab-item>
            </v-tabs-items>
          </perfect-scrollbar>
        </v-col>
      </v-layout>
      <!--end::Profile-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import OverviewTab from "@/view/pages/profile/Setting/OverviewTab";
import BillingPlanTab from "@/view/pages/profile/Setting/BillingPlanTab";
//import { GET } from "@/core/services/store/request.module";
import { GET, PATCH, UPLOAD, PUT } from "@/core/services/store/request.module";
import JwtService from "@/core/services/jwt.service";
// import SignaturePad from "signature_pad";
import ObjectPath from "object-path";

export default {
  name: "basic-profile",
  data() {
    return {
      userId: 0,
      userTab: "tab-overview",
      pageLoading: true,
      currentUser: new Object(),
      userData: new Object(),
      loginLogs: [],
      signature: null,
      customer_signature: null,
      attachment_url: null,
      tabs: [
        {
          title: "Overview",
          icon: "mdi-credit-card",
          key: "overview",
          disabled: false,
        },
        {
          title: "Billing & Plan",
          icon: "mdi-credit-card",
          key: "billing",
          disabled: false,
        },
      ],
    };
  },
  components: {
    OverviewTab,
    BillingPlanTab,
  },
  methods: {
    select_file() {
      this.$refs["select-file"].$refs["input"].click();
    },
    on_file_change(file) {
      if (file) {
        this.attachment_url = URL.createObjectURL(file);
        let formData = new FormData();
        formData.append("files[0]", file, file.name);
        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
          .then((response) => {
            this.attachment_url = ObjectPath.get(response, "data.0.file.url");
            this.$refs["select-file"].reset();
            this.update_signature();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    update_signature() {
      this.$store
        .dispatch(PATCH, {
          url: `user/${this.userId}/signature/update`,
          data: { signature: this.attachment_url },
        })
        .catch((error) => {
          this.logError(error);
        });
    },

    submitSignature() {
      const _this = this;

      if (_this.customer_signature.isEmpty()) {
        return false;
      }
      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(PUT, {
          url: `user/${this.userId}/signature/update`,
          data: {
            customer: _this.customer_signature.toDataURL(),
          },
        })
        .then((/* data */) => {
          //this.getUser();
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.loadContent();
          _this.customer_signature.clear();
        });
    },

    logoutUser() {
      JwtService.destroyAuth();
    },
    getUserProfile() {
      const _this = this;
      try {
        _this.currentUser = JwtService.currentUser();
        _this.userId = _this.currentUser.id;
      } catch (error) {
        _this.logError(error);
      }
    },
    getUser() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "user/" + _this.userId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    loadContent() {
      const _this = this;
      _this.pageLoading = true;
      _this
        .getUser()
        .then((response) => {
          _this.userData = response;
          _this.attachment_url = response.signature;
          _this.loginLogs = response.login_details || [];
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    // initSignature() {
    //   const _this = this;
    //   _this.$nextTick(() => {
    //     let ccanvas = _this.$refs["customer_signature"];
    //     let ccanvasDiv = _this.$refs["customer_signature_div"];
    //     // let cparentDiv = document.getElementById("signature-pad");
    //     let cparentWidth = 280; //cparentDiv.offsetWidth / 2;
    //     ccanvas.setAttribute("width", cparentWidth);
    //     ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");
    //     _this.customer_signature = new SignaturePad(ccanvas);
    //   });
    // },
  },
  mounted() {
    this.getUserProfile();
    this.loadContent();
    // this.initSignature();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Profile" },
      { title: "Account" },
    ]);
  },
  computed: {
    getProfileImage() {
      if (
        this.lodash.isEmpty(this.currentUser) === false &&
        this.lodash.isEmpty(this.currentUser.profile_logo) === false
      ) {
        return this.currentUser.profile_logo.file.url;
      }
      return null;
    },
    valid_signature() {
      return this.customer_signature
        ? this.customer_signature.isEmpty()
        : false;
    },
  },
};
</script>
<style scoped>
.sidebar--detail {
  width: 320px;
  max-width: 320px;
  height: calc(100vh - 95px);
  overflow: auto;
}
.main-section--detail {
  flex-grow: 1;
  width: calc(100% - 320px);
  max-width: calc(100% - 320px);
}
</style>
