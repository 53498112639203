<template>
  <div class="overview">
    <v-card flat class="custom-grey-border remove-border-radius mt-4">
      <v-card-title class="headline grey lighten-4">
        <span
          class="font-weight-700 custom-headline color-custom-blue font-size-17"
        >
          Current Plan</span
        >
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <v-row>
          <v-col cols="12" md="6">
            <v-card outlined class="grey lighten-3 pa-5">
              <div>
                <div class="d-flex justify-content-between mb-1">
                  <div>
                    <v-chip
                      class="text-uppercase"
                      color="black"
                      text-color="white"
                      label
                    >
                      <span style="font-size: 15px; letter-spacing: 0.6px">
                        Bussiness Plan
                      </span>
                    </v-chip>
                  </div>
                  <div class="font-weight-bold">
                    <span class="text-h4">$</span
                    ><span class="text-h2 font-weight-bold">300</span>/month
                  </div>
                </div>
                <div class="d-flex align-center">
                  <div class="font-weight-500">Payment Type :</div>
                  <v-chip
                    label
                    small
                    outlined
                    color="blue"
                    class="ml-3 text-white"
                  >
                    <span
                      style="font-size: 15px; letter-spacing: 0.6px"
                      class="text-uppercase"
                    >
                      Yearly
                    </span>
                  </v-chip>
                </div>
                <div class="py-1 mt-3">
                  <v-icon color="green" size="20" class="mr-2"
                    >mdi-check-all</v-icon
                  >
                  <span class="font-weight-700">04/15 Users</span>
                </div>
                <div class="py-1">
                  <v-icon color="green" size="20" class="mr-2"
                    >mdi-check-all</v-icon
                  >
                  <span class="font-weight-500"
                    >Last payment -
                    <span class="font-weight-700">30 July 2023 (800 SGD)</span>
                  </span>
                </div>
                <div class="py-1">
                  <v-icon color="green" size="20" class="mr-2"
                    >mdi-check-all</v-icon
                  >
                  <span class="font-weight-500"
                    >Next payment
                    <span class="red--text font-weight-700">*</span> -
                    <span class="font-weight-700">30 July 2024 </span>
                  </span>
                </div>
                <div class="py-1">
                  <span class="font-weight-500 pl-7 d-inline-block">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Eius, rem.
                  </span>
                </div>
              </div>
              <v-card-actions class="px-0">
                <v-btn
                  rounded
                  depressed
                  class="custom-bold-button white--text"
                  color="cyan"
                >
                  Contact Sales
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card elevation="0">
              <div>
                <div class="text-h6 mb-1">Active until 20 Jan 2023</div>
                <div>
                  We will send you a notification upon subscription expiration
                </div>
              </div>
            </v-card>
            <div class="pa-3 mt-5 orange lighten-4 rounded">
              <div class="text-h6 mb-1">We need your attention!</div>
              <div>Your plan requires update</div>
            </div>
            <div class="mt-5">
              <div class="text-h6 mb-2">
                <div class="d-flex justify-content-between mb-1">
                  <h6 class="">Remaining Days</h6>
                  <h6 class="">26 of 30 Days</h6>
                </div>
                <v-progress-linear
                  rounded
                  v-model="upgradeplan"
                  color="orange"
                  height="8"
                ></v-progress-linear>
              </div>
              <span>6 days remaining until your plan requires update</span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat class="custom-grey-border remove-border-radius mt-4">
      <v-card-title class="headline grey lighten-4">
        <span
          class="font-weight-700 custom-headline color-custom-blue font-size-17"
        >
          Active Users (04)
        </span>
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <v-simple-table>
          <thead>
            <tr style="background-color: #f3f6f9; color: #24326d">
              <td class="font-size-13 text-uppercase font-weight-500">Name</td>
              <td class="font-size-13 text-uppercase font-weight-500">Role</td>
              <td class="font-size-13 text-uppercase font-weight-500">Email</td>
              <td class="font-size-13 text-uppercase font-weight-500">Phone</td>
              <td class="font-size-13 text-uppercase font-weight-500">Date</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in teamListData"
              :key="index"
              :class="{
                'custom-border-top': index == 0,
                'orange_lighten-5': index % 2 == 0,
              }"
            >
              <td class="font-size-14 font-weight-500">
                <v-avatar size="35" color="cyan" class="text-white">
                  <template v-if="row.avatarimg">
                    <img :src="row.avatarimg" alt="John" />
                  </template>
                  <template v-else>{{ getAvatarText(row.name) }}</template>
                </v-avatar>
                {{ row.name }}
              </td>
              <td class="font-size-13">{{ row.role }}</td>
              <td class="font-size-13">{{ row.email }}</td>
              <td class="font-size-13">{{ row.phone }}</td>
              <td class="font-size-13">{{ row.date }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "overview",
  data() {
    return {
      upgradeplan: 75,
      teamListData: [
        {
          id: 1,
          name: "John Doe",
          role: "Sale Manager",
          avatarimg: "https://cdn.vuetifyjs.com/images/john.jpg",
          email: "john@gmail.con",
          phone: "+65-99880099",
          date: "15/01/2024",
        },
        {
          id: 2,
          name: "Maxwell",
          role: "Sale Manager",
          avatarimg: "",
          email: "Maxwell@gmail.con",
          phone: "+65-99880099",
          date: "15/01/2024",
        },
        {
          id: 3,
          name: "Johnson Mark",
          role: "Sale Manager",
          avatarimg: "",
          email: "Johnson@gmail.con",
          phone: "+65-99880099",
          date: "18/01/2024",
        },
        {
          id: 4,
          name: "Denial Richerd",
          role: "Sale Manager",
          avatarimg: "",
          email: "denial@gmail.con",
          phone: "+65-99880099",
          date: "17/01/2024",
        },
      ],
    };
  },
};
</script>
