<template>
  <div class="billing-plan">
    <v-card flat class="custom-grey-border remove-border-radius mt-4">
      <v-card-title class="headline grey lighten-4">
        <span
          class="font-weight-700 custom-headline color-custom-blue font-size-17"
        >
          Billing Address</span
        >
        <v-spacer></v-spacer>
        <v-btn
          rounded
          depressed
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          >Edit Address</v-btn
        >
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <v-row>
          <v-col cols="12" md="6">
            <table width="100%">
              <tr>
                <td class="py-1 font-weight-500" width="120">Company Name</td>
                <td class="py-1">John Doe</td>
              </tr>
              <tr>
                <td class="py-1 font-weight-500">Billing Email</td>
                <td class="py-1">john@gmail.com</td>
              </tr>
              <tr>
                <td class="py-1 font-weight-500">Billing Address</td>
                <td class="py-1">
                  ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12" md="6">
            <table width="100%">
              <tr>
                <td class="py-1 font-weight-500" width="120">Phone No.</td>
                <td class="py-1">+65 1234 5678</td>
              </tr>
              <tr>
                <td class="py-1 font-weight-500">Country</td>
                <td class="py-1">Singapore</td>
              </tr>
              <tr>
                <td class="py-1 font-weight-500">Postal Code</td>
                <td class="py-1">403130</td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat class="custom-grey-border remove-border-radius mt-4">
      <v-card-title class="headline grey lighten-4">
        <span
          class="font-weight-700 custom-headline color-custom-blue font-size-17"
        >
          Billing List</span
        >
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <v-simple-table>
          <thead>
            <tr style="background-color: #f3f6f9; color: #24326d">
              <td class="font-size-13 text-uppercase font-weight-500">Date</td>
              <td class="font-size-13 text-uppercase font-weight-500">
                Invoice/Credit No.
              </td>
              <td class="font-size-13 text-uppercase font-weight-500">
                Payment Method
              </td>
              <td class="font-size-13 text-uppercase font-weight-500">
                Amountd
              </td>
              <td
                class="font-size-13 text-uppercase font-weight-500"
                style="width: 150px !important"
              >
                Action
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in billingListData"
              :key="index"
              :class="{
                'custom-border-top': index == 0,
                'orange_lighten-5': index % 2 == 0,
              }"
            >
              <td class="font-size-14 font-weight-500">{{ row.date }}</td>
              <td class="font-size-14 font-weight-500">
                <v-chip outlined color="cyan" label>
                  {{ row.invoice_no }}
                </v-chip>
              </td>
              <td class="font-size-14 font-weight-500">
                <img
                  width="50"
                  contain
                  :src="$assetURL(row.payment_method)"
                  alt="visa"
                />
              </td>
              <td class="font-size-14 font-weight-500">$ {{ row.amount }}</td>
              <td class="font-size-14 font-weight-500">
                <v-btn class="rounded" icon color="red">
                  <v-icon dark> mdi-file-pdf-box </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "billing-plan",
  data() {
    return {
      billingListData: [
        {
          id: 1,
          invoice_no: "INV-001",
          payment_method: "media/logos/mastercard.png",
          amount: 150.0,
          date: "15/01/2024",
        },
        {
          id: 2,
          invoice_no: "INV-002",
          payment_method: "media/logos/visa.png",
          amount: 120.0,
          date: "15/01/2024",
        },
        {
          id: 3,
          invoice_no: "INV-003",
          payment_method: "media/logos/mastercard.png",
          amount: 150.0,
          date: "18/01/2024",
        },
        {
          id: 4,
          invoice_no: "INV-004",
          payment_method: "media/logos/visa.png",
          amount: 140.0,
          date: "17/01/2024",
        },
      ],
    };
  },
};
</script>
